/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

==========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MyMDBox from "../../components/MyMDBox";
import MyMDButton from "../../components/MyMDButton";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import { useCallback, useEffect, useState } from "react";
import MyMDTypography from "../../components/MyMDTypography";
import useNav from "../../uses/useNav";
import apis from "../../apis";
import MyEdit from "../../examples/MyEdit";
import MyMDInput from "../../components/MyMDInput";
import MyMDSelect from "../../components/MyMDSelect";
import { useUser } from "../../contexts/user";
import { Navigate } from "react-router-dom";
import { Card, Container, Divider, Grid ,Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { T_elevStatus, T_elevator, T_floorInfo } from "../../apis/elevator";
import CheckHistory from "./check/CheckHistory";
import { reverse } from "dns";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Icon from "@mui/material/Icon";
import { Box, bgcolor, height } from "@mui/system";
import MyTable from "../../examples/MyTable";
import { T_elevError } from "../../models/error";
import MyTableStyle from "../../examples/MyTableStyle";
import ElevError from "./listcom/ElevError";
import ElevCheck from "./listcom/ElevCheck";
import ElevChange from "./listcom/ElevChange";
import ElevTrouble from "./listcom/ElevTrouble";
import ElevAlarm from "./listcom/ElevAlarm";
import MyIcon from "../../components/MyIcon";

type T_selectGroup = {
  name: string;
  value: string;
};

function ElevatorMonitorLayout(props:any) {
  const nav = useNav();
  const { user } = useUser();
  
  const state = nav.state as {elevator:T_elevator}
  const elevator = state.elevator;


  const isCreate = nav.pathname.includes("/elevator/monitor");
  const [isInit, setIsInit] = useState(false);
//  const [elevator, setElevator] = useState<T_elevator| null>(null);
  const [group, setGroup] = useState<T_selectGroup | null>(null);
  const [memo, setMemo] = useState("");
  const [groupList, setGroupList] = useState<T_selectGroup[] | null>([]);

  const [statusModalTarget, setStatusModalTarget] = useState<T_elevator | null>(null);

  


  const [elev,setElev] = useState<T_elevStatus>()
  const [floor,setFloor] = useState<T_floorInfo[]|null|undefined>();
    
  const getElevStatus = useCallback(async () => {
    try {
       const result = await apis.elevator.getElevStatus(elevator.elevatorNo);
    if (result.isSuccess) {
                setElev(result.data);
    }
            //   if (result.isSuccess) {
    //   }
        console.log(result)
    } catch (err: any) {
      console.log(err.message);
    }
  }, []);    

  const getFloorinfo = useCallback(async () => {
    try {
       const result = await apis.elevator.getElevFloor(elevator.elevatorNo)
    
       setFloor(result.data.reverse());
    //   if (result.isSuccess) {
    //   }
    //    console.log(result)
     
    } catch (err: any) {
      console.log(err.message);
    }
  }, []);    
  useEffect(() => {
    getFloorinfo();
    }, []);

  useEffect(() => {
    const interval = setInterval(() => {
       getElevStatus();
    }, 1000);
    return () => clearInterval(interval);
    
  }, []);


  const sendMqtt = async(topic:string,message:string) =>{
      const result = await apis.message.sendMqtt(
        {topic:topic,
          message:message,
        }
      )

      if(result.isSuccess){
        alert("발송이 완료되었습니다.");
      }else{
        alert(result.msg);
      }

  }
 
  const getDriverStatus= (elev:T_elevStatus|null|undefined) => {
    if(!elev?.auto) {
        return( <MyMDTypography variant="h5" color="info"
        alignContens="center">정상 운행</MyMDTypography>)
    }else{
        return(<MyMDTypography variant="h5" color="error"
        alignContens="center">점검중</MyMDTypography>) 
    }
  }
  
  const getUpStatus = (elev:T_elevStatus|null|undefined) => {
        if(elev?.up){
            if(elev?.move){
                return(<UpAnimation />)

           }else{
                return(
                    <img src="/images/up01.png" width="40" height="40" />
                )    
            }
        }else{
            if(elev?.down){
                if(elev?.move){
                    return(
                        <DownAnimation/>
                     )
                         
               }else{
                    return(
                        <img src="/images/down01.png" width="40" height="40" />
                    )    
                }
            }else{
                <div  style={{width:50,height:50}} />
            }
            
        }
  }

    
  const getDoorStatus = (elev:T_elevStatus|null|undefined) => {
    if(elev?.doorOpen){
            return( <img src="/images/close_ani00.png" width="40" height="40" />)    
    }else{
        return( <img src="/images/open_ani00.png" width="40" height="40" />)    
    }
   }


  if (!user) {
    return <Navigate replace to={`/signin`} />;
  } else {
    return (
      <>
      <DashboardLayout>
        <DashboardNavbar />
        <Card style={{width:'100%',alignItems:'center',padding:10} }>
        <MyMDBox
          variant="gradient"
          bgColor="info"
          color="dark"
          coloredShadow="white"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContens="center"
          width="50%"
          height="4rem"
          mt={-4}
        >
        <MyMDTypography variant="h5" color="white" alignContens="center">{elevator?.buldNm} {elevator?.installationPlace}</MyMDTypography>
        </MyMDBox>

       <Grid container spacing={1} pt={5}>
        <Grid style={{width:230}} item xs={2} >

        <MyMDBox mb={5}  display="flex" flexDirection = "column" 
        >
           
        <Card 
         style={{backgroundColor:"#344767",marginBottom:10}}
         >
        <MyMDBox display="flex" pt={1} px={4} flexDirection="column" >
        <MyMDBox
          pt={1}
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
        <MyMDBox
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            margin:0,
          }}
        >
           <MyMDBox
            sx={{flex:1,flexDirection:"row", display: "flex",}}
           >
            <MyMDTypography variant="h1" sx={{flex:1,margin:-5,display: "flex",width:70,justifyContent:"center",alignItems:"center",alignContents:"center"}} m={0} color="error">{floor?.find(item => item.floor == elev?.cfloor)?.display}</MyMDTypography>
            <div style={{marginLeft:10,marginRight:10,width:50,height:50}}>{getUpStatus(elev)}</div>
          </MyMDBox>  
          </MyMDBox>
        <MyMDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
         >
             {getDoorStatus(elev)}
          </MyMDBox>
        </MyMDBox>
        <MyMDBox
          variant="gradient"
          color="dark"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContens="center"
          width="10rem"
          height="2rem"
          my={1}
          py={0}
        >
        <MyMDTypography variant="h4" color="error" sx={{marign:-5}}
          alignContens="center">{getDriverStatus(elev)}</MyMDTypography>
        </MyMDBox>
           </MyMDBox>
        </Card>            
        <Card 
         style={{backgroundColor:"#999999"}}
         >  
        <MyMDBox display="flex" pt={1} px={1} flexDirection="column"  >
          {floor?.map((item,index) => {

              return(<FloorInfo1 info={{floor:item,elev:elev}} />);

          })
         }

        </MyMDBox>
         </Card>
        </MyMDBox>
        </Grid>
        <Grid mx={3}  item xs={9}  >
          <Grid container spacing={0} >
          <MyMDBox
           pb={2}
          >
          <MyMDButton 
                variant="gradient"
                color="light"
                style={{margin:5,padding:5}}
                onClick={()=>{
                  sendMqtt("/elevator/"+elevator.elevatorNo+"/monitor","reboot")
                }}                
          >모니터 재부팅</MyMDButton>
          <MyMDButton
          variant="gradient"
          color="light"
          style={{margin:5,padding:5}}
          onClick={()=>{
            sendMqtt("/elevator/"+elevator.elevatorNo+"/monitor","end")
          }}
          >모니터 알림 초기화</MyMDButton>
          <MyMDButton
          variant="gradient"
          color="light"
          style={{margin:5,padding:5}}
          onClick={()=>{
            sendMqtt("/elevator/"+elevator.elevatorNo+"/monitor","on")
          }}
          >모니터 켜기</MyMDButton>
          <MyMDButton
          variant="gradient"
          color="light"
          style={{margin:5,padding:5}}
          onClick={()=>{
            sendMqtt("/elevator/"+elevator.elevatorNo+"/monitor","off")
          }}
          >모니터 끄기</MyMDButton>
          </MyMDBox>
          <ElevError elevatorNo={elevator.elevatorNo}/>
          <ElevAlarm elevatorNo={elevator.elevatorNo}/>
          <ElevCheck elevatorNo={elevator.elevatorNo}/>
          <ElevChange elevatorNo={elevator.elevatorNo}/>
          <ElevTrouble elevatorNo={elevator.elevatorNo}/>
          </Grid>
        </Grid>
       </Grid>  
       </Card>
      </DashboardLayout>
    </>
    );
  }
}



export type T_floorInfoProps ={
  info:{    
    floor : T_floorInfo;
    elev? : T_elevStatus;
  };
}


function FloorInfo1(info : T_floorInfoProps){

   
      const getDoorStatus = (elev:T_elevStatus|null|undefined) => {
        if(elev?.doorOpen){
                return( <img src="/images/close_ani00.png" width="30" height="30" />)    
        }else{
            return( <img src="/images/open_ani00.png" width="30" height="30" />)    
        }
      }

      return(
        <MyMDBox>
        <MyMDBox 
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
          >
          <MyMDBox style={{display:"flex",width:60,justifyContent:"center",alignItems:"center",alignContens:"center"}}>
             <MyMDTypography  fontSize={25} style={{alignItems:"center",justifyContent:"center",alignContens:"center",color:info.info.elev?.innerCall[info.info.floor.floor-1]?"red":"white"}}>{info.info.floor.display}</MyMDTypography> 
            </MyMDBox>
            <MyMDBox mx={3} style={{display:"flex",width:50,justifyContent:"center",alignItems:"center",alignContens:"center"}}>
             {info.info.elev?.cfloor==info.info.floor.floor?getDoorStatus(info.info.elev):<MyMDTypography width="20" height="20">&nbsp;&nbsp;&nbsp;&nbsp;</MyMDTypography>}
            </MyMDBox>
            <MyMDBox flexDirection="row" mx={2}>
            <MyMDBox>
            <MyMDTypography width="20" height="15" fontSize={15} color={info.info.elev?.upCall[info.info.floor.floor-1]?"primary":"inherit"}>▲</MyMDTypography>
            </MyMDBox>
            <MyMDBox>
            </MyMDBox>
            <MyMDTypography width="20" height="15" fontSize={15}  color={info.info.elev?.downCall[info.info.floor.floor-1]?"primary":"inherit"}>▼</MyMDTypography>
            </MyMDBox>
          </MyMDBox>
              <Divider style={{display:"flex",height:1,backgroundColor:"#000000",margin:0,padding:0}} />
          </MyMDBox>
      )

}


function DownAnimation(){
  const [index, setIndex] = useState(0);
  const images = ["/images/down01.png","/images/down02.png","/images/down03.png","/images/down04.png","/images/down05.png","/images/down06.png"];

  useEffect(() => {
      const interval = setInterval(() => {
          setIndex((index) => index+1);
      }, 200);
      return () => clearInterval(interval);
      
    }, []);
    if (index >= images.length) {
      setIndex(0);
    }
 
  return (
      <img src={images[index]} width="50" height="50" />  
    );
}


function UpAnimation(){
  const [index, setIndex] = useState(0);
  const images = ["/images/up01.png","/images/up02.png","/images/up03.png","/images/up04.png","/images/up06.png"];

  useEffect(() => {
      const interval = setInterval(() => {
          setIndex((index) => index+1);
      }, 200);
      return () => clearInterval(interval);
      
    }, []);
    if (index >= images.length) {
      setIndex(0);
    }
 
  return (
      <img src={images[index]} width="50" height="50" />  
    );
}

function OpenAnimation(){
  const [index, setIndex] = useState(0);
  const images =  ["/images/open_ani00.png","/images/open_ani01.png","/images/open_ani02.png","/images/open_ani03.png"];

  useEffect(() => {
      const interval = setInterval(() => {
          setIndex((index) => index+1);
      }, 200);
      return () => clearInterval(interval);
      
    }, []);
    if (index >= images.length) {
      setIndex(0);
    }
 
  return (
      <img src={images[index]} width="50" height="50" />  
    );
}


function CloseAnimation(){
  const [index, setIndex] = useState(0);
  const images =  ["/images/close_ani00.png","/images/close_ani01.png","/images/close_ani02.png","/images/close_ani03.png"];

  useEffect(() => {
      const interval = setInterval(() => {
          setIndex((index) => index+1);
      }, 200);
      return () => clearInterval(interval);
      
    }, []);
    if (index >= images.length) {
      setIndex(0);
    }
 
  return (
      <img src={images[index]} width="50" height="50" />  
    );
}



export default ElevatorMonitorLayout;
