import { T_checkHist } from "../models/elevator";
import { string } from "prop-types";
import { getApi, sendApi } from ".";
import { T_check } from './check';
import { T_codeDtl } from "./code";
import { T_commonResponse } from "../models/common";


export type T_elevStatus =  {
  cfloor:number;
  errorCode: number;
  errorFloor: number;
  upCall : [];
  downCall: [];
  innerCall: [];
  speed: number;
  full: boolean;
  down: boolean;
  floorHeight: boolean;
  gisa: boolean;
  moving: boolean;
  auto: boolean;
  fire: boolean;
  alarmElectric: boolean;
  doorLock: true;
  fail: true;
  earthQuake: boolean;
  doorOpen: boolean;
  doorError: boolean;
  test: boolean;
  over: boolean;
  lock: boolean;
  doorClose: boolean;
  move: boolean;
  safePoint: true;
  group: boolean;
  error: boolean;
  up: boolean  
}

export type T_floorInfo = {
  floor :number;
  display : string;
}

export type T_statusResult = {
  msg: string;
  isSuccess: boolean;
  data: T_elevStatus;
};

export type T_floorResult = {
  msg: string;
  isSuccess: boolean;
  data: T_floorInfo [];
};



export type T_elevatorGroup = {
  elevatorNo?: string;
  groupCode?: string;
}
export type T_elevator = {
  elevatorNo: string;
  address1: string;
  address2: string;
  mntCpnyNm: string;
  buldNm:string;
  elvtrKindNm:string;
  lastInspctDe:string; //마지막 정기점검일시
  ratedCap:string; // 용량
  applcBeDt:string;
  applcEnDt:string;
  areaNo:string;
  buldMgtNo1:string;
  buldMgtNo2:string;
  buldPrpos:string;
  divGroundFloorCnt:string;
  divUndgrndFloorCnt:string;
  elvtrAsignNo:string;
  elvtrDivNm:string;
  elvtrFormNm:string;
  elvtrModel:string;
  elvtrStts:string;
  inspctInstt:string;
  installationDe:string;
  installationPlace:string;
  lastInspctKind:string;
  lastResultNm:string;
  liveLoad:string;
  manufacturerName:string;
  manufacturerTelno:string;
  mnfcturCpnyCd:string;
  mntCpnyTelno:string;
  partcpntNm:string;
  partcpntTelno:string;
  ratedSpeed:string;
  shuttleFloorCnt:string;
  shuttleMngrNm:string;
  shuttleMngrTelno:string;
  shuttleSection:string;
  sigunguCd:string;
  standardKey:string;
  zipCd1:string;
  zipCd2:string;
  frstInstallationDe:string;
  groupCode?:string;
  groupName?:string;
  groupinfo?: T_codeDtl;
 
};




export type T_elevatorGetListData = {
  page:number,
  pagesize:number,
  groupCode:string,
};

export type T_elevatorGetListResult = {
  msg: string;
  isSuccess: boolean;
  elevatorList: T_elevator[];
  totalPage:number;
  total:number;
};

export type T_commmResult = {
  isSuccess:boolean;
  msg : string;
}

async function saveElevator(data:T_elevator){

  console.log("response:",data);
  
    const response = await sendApi(
      {
        method:"post",
        url:"/elev/save",
        data:{
          ...data,
          elevinfo:data,
        }
      }
    )
    const result : T_commmResult = {
      ...response.data
    }

    return result;
}


async function updateElevator(data:T_elevatorGroup){

 
    const response = await sendApi(
      {
        method:"post",
        url:"/elev/update",
        data:{
          ...data,
          elevinfo:data,
        }
      }
    )
    console.log(response)
    const result : T_commmResult = {
      ...response.data
    }

    return result;
}



async function getList(data?: T_elevatorGetListData) {
  const response = await sendApi({
    method: "post",
    url: "/elev/list",
    data: {
      ...data,
    },
  });

  const elevatorList = response.data.data.list;
  const totalPage = response.data.data.pages;
  const total = response.data.data.total;
  delete response.data.data;
  const result: T_elevatorGetListResult = {
    ...response.data,
    elevatorList,
    totalPage,
    total,
  };
  return result;
}

export type T_elevatorGetData = {
  elevatorNo: string;
};

export type T_elevatorGetResult = {
  msg: string;
  isSuccess: boolean;
  elevator: T_elevator | null;
};
export type T_checkHistResult = {
  msg: string;
  isSuccess: boolean;
  list: T_checkHist[] | null;
};

async function get(data: T_elevatorGetData) {
  const response = await sendApi({
    method: "post",
    url: "/elev/get",
    data: {
      ...data,
    },
  });
  let elevator: T_elevator | null = null;
  elevator = response.data.data;
  delete response.data.data;
  const result: T_elevatorGetResult = {
    ...response.data,
    elevator,
  };
  return result;
}

export type T_checkGetCheckingListData = {};

export type T_checkGetCheckingListResult = {
  msg: string;
  isSuccess: boolean;
  checkingElevatorList: T_check[];
};

async function getCheckingList(data?: T_checkGetCheckingListData) {
  const response = await sendApi({
    method: "post",
    url: "/check/checkelevlist",
    data: {
      ...data,
    },
  });
  console.log(data);
  const checkingElevatorList = response.data.data;
  delete response.data.data;
  const result: T_checkGetCheckingListResult = {
    ...response.data,
    checkingElevatorList,
  };
  return result;
}


async function getApiInfo(elevno: string) {
  const response = await getApi({
    method: "post",
    url: "/elev/apiget",
    data: {
      elevatorNo:elevno,
    },
  });
  let elevator: T_elevator | null = null;
  elevator = response.data.data;
  response.data.isSuccess = response.data?.code === "1";
  response.data.msg = response.data?.message || "";
  delete response.data.data;
  const result: T_elevatorGetResult = {
    ...response.data,
    elevator,
  };
 
  return result;
}


async function getApiCheck(elevno: string) {
  const response = await getApi({
    method: "post",
    url: "/elev/apicheckget",
    data: {
      elevatorNo:elevno,
    },
  });
  console.log("res:",response);
  let list: T_checkHist[] | null = null;
  list = response.data.data;
  response.data.isSuccess = response.data?.code === "1";
  response.data.msg = response.data?.message || "";
  delete response.data.data;
  const result: T_checkHistResult = {
    ...response.data,
    list,
  };
 
  return result;
}



async function getElevStatus(elevno:String){

  const response = await getApi({
    method: "post",
    url: "/elev/status",
    data: {
      elevatorNo:elevno,
    },
  });


  response.data.isSuccess = response.data?.code === "1";

  const result: T_statusResult = {
    ...response.data,
  };
 
  return result;
//  console.log("elevlist",list);
}


async function getElevFloor(elevno:String){

  const response = await getApi({
    method: "post",
    url: "/elev/floors",
    data: {
      elevatorNo:elevno,
    },
  });


  response.data.isSuccess = response.data?.code === "1";

  const result: T_floorResult = {
    ...response.data,
  };
 
  return result;
//  console.log("elevlist",list);
}



async function getElevErrorList(data:any){

  const response = await sendApi({
    method: "post",
    url: "/alarm/eleverror/list",
    data: data,
  });

  const list = response.data.data.list;
  const pageNum = response.data.data.pageNum;
  const totalPage = response.data.data.pages;
  const total = response.data.data.total;
   delete response.data.data;
   const result : T_commonResponse = {
     ...response.data
     ,pageNum:pageNum
     ,totalPage:totalPage
     ,total:total
     ,data:list,
   }

   return result;
  }

 
async function getElevAlarmList(data:any){

  const response = await sendApi({
    method: "post",
    url: "/alarm/list",
    data: data,
  });

  const list = response.data.data.list;
  const pageNum = response.data.data.pageNum;
  const totalPage = response.data.data.pages;
  const total = response.data.data.total;
   delete response.data.data;
   const result : T_commonResponse = {
     ...response.data
     ,pageNum:pageNum
     ,totalPage:totalPage
     ,total:total
     ,data:list,
   }

   return result;
  }
  

const elevator = {
  getList,
  get,
  getCheckingList,
  getApiInfo,
  saveElevator,
  getApiCheck,
  updateElevator,
  getElevStatus,
  getElevFloor,
  getElevErrorList,
  getElevAlarmList
};

export default elevator;
