import { Box, Button, Input, TextField, Typography } from "@mui/material";
import BasicLayout from "../../layouts/authentication/components/BasicLayout";
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import MyMDInput from "../../components/MyMDInput";
import { useCallback, useState } from "react";
import MyMDButton from "../../components/MyMDButton";
import MDButton from "../../components/MDButton";
import { T_loginAdminResult } from "../../apis/user";
import apis from "../../apis";
import { useUser } from "../../contexts/user";
import { Navigate } from "react-router-dom";


export default function Login(){
    const { user, setUser } = useUser();
    const [userId, setUserId] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const getLogin = useCallback(async () => {
        try {
          const result : T_loginAdminResult = await apis.user.loginAdmin({
            userId:userId,
            userPass : userPassword
          });
    
          console.log(result)
    
          if(result.isSuccess){
            if(result.user) setUser(result.user);
          }else{
            alert(result.msg);
          }
        } catch (err: any) {
          console.log(err.message);
        }
      }, [userId,userPassword]);
      if (user) {
        return <Navigate to="/dashboard" />;
      } else {
    return(
       
        <PageLayout>
    <Box
      sx={{
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        background:"white",
        height: '100vh', // 100% 화면 높이
  
      }}
    >
         <Typography
     variant="h1" // 텍스트 크기를 크게 지정
     component="h1" // 텍스트가 제목처럼 보이도록 설정
    
     sx={{
        paddingBottom:3,
       color: 'grey', // 텍스트 색상 설정
       justifyContent:'center'
       ,textAlign:'center'
     }}
     >블랙박스 관리자</Typography>
      <Box
        sx={{
          width: 500,
          height: 600,
          borderTop: '3px solid black', 
          borderLeft:'1px solid grey',
          borderBottom:'1px solid grey',
          borderRight:'1px solid grey',
          color: 'white',
          lineHeight: '200px',
          padding:2,
        }}
      >
     <Typography
     variant="h1" // 텍스트 크기를 크게 지정
     component="h1" // 텍스트가 제목처럼 보이도록 설정
     sx={{
       paddingTop:10,
       color: 'black', // 텍스트 색상 설정
       justifyContent:'center'
       ,textAlign:'center'
     }}
     >LOGIN</Typography>
     <Typography
     variant="h5" // 텍스트 크기를 크게 지정
     component="h2" // 텍스트가 제목처럼 보이도록 설정
     sx={{
        paddingTop:1,
        paddingLeft:10,
        paddingRight:10,
        color: 'black', // 텍스트 색상 설정
     }}
     >로그인 하시면 엘리베이터 정보를 <br/>관리하실수 있습니다. </Typography>     
        <Box
        sx={{width:'100%',heigh:100,marginTop:5,padding:3,justifyContent:"flex-start"
            ,textAlign:"flex-start"
            }}
        >
        <Typography
     variant="h5" // 텍스트 크기를 크게 지정
     component="h2" // 텍스트가 제목처럼 보이도록 설정
     sx={{
        color: 'black' // 텍스트 색상 설정
        ,textAlign:'flex-start'
        ,paddingBottom:2
     }}
     >회원로그인</Typography>  
 <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', maxWidth: '100%' }}>


      {/* <TextField  sx={{border:0,borderRadius:0,marginTop:0}}  fullWidth label="ID"
      value={userId}
      onChange={(evt: any) => {
        setUserId(evt.target.value);
      }}  
    /> */}
      {/* <TextField  sx={{marginTop:0,borderRadius:0}} fullWidth label="PASSWORD"
         value={userPassword}
         onChange={(evt: any) => {
            setUserPassword(evt.target.value);
          }}
      /> */}
               <input
                            type="password"
                           name="board_title"
                           placeholder="아이디"
                           style={{
                              width: '100%',
                              borderRadius:0,
                              background:"#eeeeee"
                           }}
                           value={userId}
                           onChange={(event)=>{
                            setUserId(event.target.value);
                           }}
        />      

               <input
                            type="password"
                           name="board_title"
                           placeholder="비밀번호"
                           style={{
                              width: '100%',
                              borderRadius:0,
                              background:"#eeeeee"
                           }}
                           value={userPassword}
                           onChange={(event)=>{
                            setUserPassword(event.target.value);
                           }}
                />      

            <Button
               style={{
                marginTop:25,
                height:65,
                width: '100%',
                borderRadius:0,
                background:"#555555",
               }}
                  fullWidth
                  onClick={()=>{
                    getLogin();
                  }}
                >
                  <Typography variant="h5" color='#ffffff'>로그인</Typography> 
            </Button>
    </div>
 
          </Box>
    </Box>
    </Box>
        </PageLayout>                  
   )
                }

 }