import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import '../../css/style.css';
import MyMDInput from "../../components/MyMDInput";
import MyMDBox from "../../components/MyMDBox";
import MyMDButton from "../../components/MyMDButton";
import { useUser } from "../../contexts/user";
import { useState } from "react";
import formats from "../../modules/formats";
import apis from "../../apis";
import { Button } from "@mui/material";
import MyEdit from "../../examples/MyEdit";
import MyMDTypography from "../../components/MyMDTypography";
import useNav from "../../uses/useNav";
import { useProgress } from "../../contexts/ProgressContextProvider";


function BoardCreate(){
   const nav = useNav();
   const { user } = useUser();
   const [totalPage, setTotalPage] = useState<null | number>(null);
   const today = new Date();
//   const nextMonth = today.setMonth(today.getMonth()+1);
   const nextWeek = today.getTime() + 7 * 24 * 60 * 60 * 1000
   const [sdate, setSdate] = useState( formats.date(new Date(), "yyyy-mm-dd"));
   const [edate, setEdate] = useState( formats.date(new Date(nextWeek), "yyyy-mm-dd"));
   
   const [title,setTilte] = useState("");
   const [selectedFile, setSelectedFile] = useState<null | any>(null);
   const [selectedFileType, setSelectedFileType] = useState<null | any>(null);
   const [sec,setSec] = useState(30);
   
   const {showProgress,setShowProgress} = useProgress();
   
   const handleFileChange = (event:any) => {
      console.log("files:",event.target.files[0]);
      const file_name = event.target.files[0].name.split(".");
      const fiel_ext = file_name[file_name.length-1].toLowerCase(); // 소문자
      // 이미지인지 확인
      const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fiel_ext);
      // 동영상인지 확인
      const isVideo = ['mp4', 'avi', 'mkv', 'wmv', 'mov'].includes(fiel_ext);
      if(isImage) setSelectedFileType("img");
      if(isVideo) setSelectedFileType("mov");
      setSelectedFile(event.target.files[0]);
    }

    const saveBoard = async() =>{
         //    alert.show("내용을 입력하세요", {
         //       position: positions.MIDDLE,
         //       type: "error",
         //       transition : 'fade',
         //   });
         if(window.confirm("정말로 입력 하시겠습니까?")){
            const formData = new FormData();
            formData.append('title',title);
            formData.append('sdate',sdate);
            formData.append('edate',edate);
            formData.append('sec',sec.toString());
            formData.append('type',selectedFileType);
            formData.append('files',selectedFile);
            setShowProgress(true);
            const response = await apis.board.insertBoard(formData);
            if(response.isSuccess){
               setShowProgress(false);
                 alert("저장이 완료 되었습니다.")        
                 nav.goBack();
            }else{
               setShowProgress(false);
               alert("저장중 오류가 발생 하였습니다.")        
            }
      //      console.log("fiel upload:",response);
         }   
         
    }



    return(
        <DashboardLayout>
        <DashboardNavbar />
        <MyEdit 
          header={{
            title: "보드등록",
          }}
        >
         <></>
         <MyMDBox pt={1} pl={1} pr={1} borderRadius="md" bgColor="#FFFFFF">
         <MyMDBox p={5}  pt={5}  pb={2} >
             <MyMDTypography ml={1} variant="h6">
             </MyMDTypography>         
               <table className="input">
                  <colgroup>
                     <col width="16%" />
                     <col width="" />
                  </colgroup>
                  {/* <tr>
                     <th>카테고리 *</th>
                     <td>
                        <select
                           name="code"
                           className="float_left"
                           onChange={changeSaveData}
                        >
                           <option>카테고리 선택</option>
                           {category.length > 0 && returnCategoryList()}
                        </select>
                     </td>
                  </tr> */}
                  <tr>
                     <th>제&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</th>
                     <td>
                        <input
                           type="text"
                           name="board_title"
                           placeholder="공지사항을 입력 해주세요"
                           style={{
                              width: '100%',
                           }}
                           value={title}
                           onChange={(e)=>{
                             // console.log("evevnt",e);
                              setTilte(e.target.value);   
                           }}
                        />
                     </td>
                  </tr>
                  <tr>
                     <th>파&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;일</th>
                     <td>
                        <input
                           type="file"
                           name="board_title"
                           placeholder="파일을 등록 해주세요"
                           style={{
                              width: '100%',
                           }}
                           onChange={handleFileChange}
                        />
                     </td>
                  </tr>
                  <tr>
                     <th>노&nbsp;&nbsp;출&nbsp;&nbsp;시&nbsp;&nbsp;간</th>
                     <td>
                        <input
                           type="text"
                           name="board_sec"
                           placeholder="노출시간"
                           style={{
                              width: '100%',
                           }}
                           value={sec}
                           onChange={(e)=>{
                              isNaN(parseInt(e.target.value))?setSec(0):setSec(parseInt(e.target.value))   
                           }}
                        />
                     </td>
                  </tr>
                  {/* <tr>
                     <th>게시 일자</th>
                     <td >
                        <div style={{display:'flex',flex:1,alignContent:'center',alignItems:'center'}}>
                     <MyMDInput
                    type="date"
                    value={sdate}
                    onChange={(evt: any) => {
                      setSdate(evt.target.value);
                    }}
                  />
                     &nbsp;-&nbsp; 
                  <MyMDInput
                    type="date"
                    value={edate}
                    onChange={(evt: any) => {
                      setEdate(evt.target.value);
                     }}
                  />
                  <MyMDButton
                  size="small"
                  variant="outlined"
                  color="secondary"
                   
                  >일주일</MyMDButton>
                  </div>
                     </td>
                  </tr> */}
        
            </table>
            <MyMDBox m={1} justifyContent="center" display="flex">
                          <MyMDBox pr={1}>
                          <MyMDButton m={1}   variant="gradient"
                            color="info"
                            onClick={()=>{
                              saveBoard();
                            }}
                            >둥록</MyMDButton>
                          </MyMDBox>
            </MyMDBox>

      </MyMDBox>
      </MyMDBox>
      </MyEdit>                              
        </DashboardLayout>
    )

}



export default BoardCreate;